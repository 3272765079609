<template>
    <section class="container-fluid">
        <div class="row mb-3 mb-lg-4">
            <div class="col-12 p-0">
                <navigation></navigation>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="content col-12 p-3 p-lg-4">
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Navigation from '../component/Navigation'
    import { isEmpty } from 'ramda'
    
    export default {
        components: { Navigation },
        methods: {
            fetchCourses () {
                this.$store.dispatch('course/fetchCourses')
            },
        },
        mounted () {
            if (this.$store.getters['auth/isAdmin']) {
                if (isEmpty(this.$store.getters['user/selectUsers'])) {
                    this.$store.dispatch('user/fetchUsers')
                }
            }
            
            if (isEmpty(this.$store.getters['course/selectCourses'])) {
                this.fetchCourses()
            }
            
            this.$store.dispatch('auth/getUser')
        },
        name: 'AdminLayout',
    }
</script>

<style lang="scss" scoped>
    @import '../resource/scss/bootstrap';
    
    section {
        background-image: url('../resource/img/blue-pattern.png');
        min-height: 100vh;
        
        @include media-breakpoint-down(md) {
            height: auto;
            min-height: 100vh;
        }
        
        .content {
            background-image: url('../resource/img/red-pattern.png');
        }
    }
</style>
