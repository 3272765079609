<template>
    <div v-if="visible">
        <slot></slot>
    </div>
</template>

<script>
import { includes } from 'ramda'
import { roles } from '@/util/user'

export default {
    name: 'VisibleFor',
    computed: {
        visible () {
            return (includes(this.role, roles) && this.role === this.$store.getters['auth/selectRole']?.id)
        },
    },
    props: {
        role: String,
    },
}
</script>
