<template>
    <nav class="navbar navbar-expand-md py-1">
        <div class="container">
            <router-link :to="{name: 'dashboard'}" class="navbar-brand fw-bold text-dark">
                <img alt="" class="img-fluid me-3" src="../resource/img/monika_majorosova.png">
                <span class="d-none d-lg-inline-block">Monika Majorošová</span>
            </router-link>
            <button aria-controls="navbar-content" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-bs-target="#navbar-content" data-bs-toggle="collapse" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div id="navbar-content" class="collapse navbar-collapse">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link :to="{name: 'dashboard'}" class="nav-link text-dark" exact>
                            <span v-if="isAdmin">Nástenka</span>
                            <span v-else>{{ $t('Nástenka') }}</span>
                        </router-link>
                    </li>
                    <li v-if="isAdmin" class="nav-item">
                        <router-link :to="{name: 'courses.index'}" class="nav-link text-dark">
                            Kurzy
                        </router-link>
                    </li>
                    <li v-if="isAdmin" class="nav-item">
                        <router-link :to="{name: 'users.index'}" class="nav-link text-dark">
                            Používatelia
                        </router-link>
                    </li>
                    <li class="nav-item d-flex justify-content-center">
                        <button class="btn btn-danger fw-bold text-white px-3" type="button" @click.prevent="logout">
                            <span v-if="isAdmin">Odhlásiť</span>
                            <span v-else>{{ $t('Odhlásiť') }}</span>
                        </button>
                    </li>
<!--                    <visible-for role="admin">
                        <li class="nav-item dropdown" style="margin-left: 1rem;">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ $i18n.locale.toUpperCase() }}
                            </a>
                            <ul class="dropdown-menu" style="min-width: 40px">
                                <li @click.prevent="changeLanguage(lang)" v-if="lang !== $i18n.locale" v-for="lang in locales"><a class="dropdown-item" href="#">{{ lang.toUpperCase() }}</a></li>
                            </ul>
                        </li>
                    </visible-for>-->
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import 'bootstrap/js/dist/collapse'
import LocalePicker from '@/component/LocalePicker.vue'
import VisibleFor from '@/component/VisibleFor.vue'
import 'bootstrap/js/dist/dropdown'
import { EventBus } from '@/main'
import { availableLocales } from '@/locale'

export default {
    name: 'Navigation',
    components: { LocalePicker, VisibleFor },
    computed: {
        isAdmin () {
            return this.$store.getters['auth/isAdmin']
        },
    },
    data () {
        return {
            locales: availableLocales,
        }
    },
    methods: {
        changeLanguage (lang) {
            if (this.$i18n.locale !== lang) {
                this.$i18n.locale = lang
                
                EventBus.$emit('locale-changed', lang)
            }
        },
        logout () {
            this.$store.dispatch('auth/logout')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../resource/scss/bootstrap';

nav {
    background-image: url('../resource/img/red-pattern.png');
    
    .navbar-brand {
        img {
            height: 75px;
            width: 75px;
            
            @include media-breakpoint-down(md) {
                height: 60px;
                width: 60px;
            }
        }
    }
    
    .nav-item {
        text-align: center;
        
        .nav-link {
            font-weight: 700;
            transition: color 250ms;
            
            &.router-link-active {
                color: $white !important;
            }
        }
    }
    
    .navbar-toggler {
        padding: 0;
        
        &-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E%0A");
        }
    }
}
</style>
