/**
 * User roles.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @type {string[]}
 */
export const roles = [
    'admin',
    'user',
]

/**
 * User statutes.
 *
 * @type {string[]}
 */
export const statuses = [
    'active',
    'inactive',
]
